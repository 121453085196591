import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Calendar from './components/Calendar';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppRouter = ({ user, setUser, handleLogout }) => {
  const location = useLocation();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const lastPath = localStorage.getItem('lastPath');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      if (lastPath && location.pathname === '/') {
        window.history.replaceState(null, '', lastPath); // Remplacer le chemin initial par le dernier chemin enregistré
      }
    }
  }, [location.pathname, setUser]);

  const isAdmin = user && user.email === 'b.marinet@wanadoo.fr';  // Vérification directe de l'email administrateur

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route path="/dashboard" element={isAdmin ? <Dashboard user={user} onLogout={handleLogout} /> : <Navigate to="/login" />} />
        <Route path="/calendar" element={user ? <Calendar user={user} onLogout={handleLogout} /> : <Navigate to="/login" />} />
        <Route path="/" element={<Navigate to={isAdmin ? "/dashboard" : (user ? "/calendar" : "/login")} />} />
      </Routes>
    </>
  );
};

export default AppRouter;
