import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [user, setUser] = useState(null);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('lastPath');
  };

  return (
    <Router>
      <AppRouter user={user} setUser={setUser} handleLogout={handleLogout} />
    </Router>
  );
};

export default App;
