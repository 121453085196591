import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Dashboard.css';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Nécessaire pour accessibilité

const Dashboard = ({ user, onLogout }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({ email: '', password: '', firstName: '', lastName: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchUsers();
    localStorage.setItem('lastPath', '/dashboard'); // Enregistrer le dernier chemin visité
  }, []);

  const fetchUsers = () => {
    axios.get('https://lequinze.site/node-app/api/users')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des utilisateurs', error);
        toast.error('Erreur lors de la récupération des utilisateurs');
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser({ ...selectedUser, [name]: value });
  };

  const addUser = () => {
    console.log('Adding user:', newUser);
    axios.post('https://lequinze.site/node-app/api/users', newUser)
      .then(response => {
        setUsers([...users, response.data]);
        setNewUser({ email: '', password: '', firstName: '', lastName: '' });
        toast.success('Utilisateur ajouté avec succès');
      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout de l\'utilisateur', error);
        toast.error('Erreur lors de l\'ajout de l\'utilisateur');
      });
  };

  const editUser = () => {
    if (!selectedUser) return;
    console.log('Editing user:', selectedUser);
    axios.put(`https://lequinze.site/node-app/api/users/${selectedUser._id}`, {
      email: selectedUser.email,
      password: selectedUser.password,
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName
    })
      .then(response => {
        const updatedUsers = users.map(user => 
          user._id === selectedUser._id ? response.data : user
        );
        setUsers(updatedUsers);
        setSelectedUser(null);
        setIsModalOpen(false); // Fermer la fenêtre modale après la modification
        toast.success('Utilisateur modifié avec succès');
      })
      .catch(error => {
        console.error('Erreur lors de la modification de l\'utilisateur', error);
        toast.error('Erreur lors de la modification de l\'utilisateur');
      });
  };

  const selectUser = (user) => {
    console.log('Selected user:', user);
    setSelectedUser(user);
    setIsModalOpen(true); // Ouvrir la fenêtre modale pour modifier l'utilisateur
  };

  const deleteUser = (userId) => {
    console.log('Deleting user:', userId);
    axios.delete(`https://lequinze.site/node-app/api/users/${userId}`)
      .then(response => {
        setUsers(users.filter(user => user._id !== userId));
        toast.success('Utilisateur supprimé avec succès');
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de l\'utilisateur', error);
        toast.error('Erreur lors de la suppression de l\'utilisateur');
      });
  };

  const showPassword = (password) => {
    toast.info(`Mot de passe : ${password}`);
  };

  return (
    <div className="dashboard">
      <h1>Tableau de Bord</h1>
      <button onClick={onLogout} className="logout-button">Déconnexion</button>
      <div className="add-user-form">
        <h2>Ajouter un nouvel utilisateur</h2>
        <input
          type="text"
          name="firstName"
          placeholder="Prénom"
          value={newUser.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Nom"
          value={newUser.lastName}
          onChange={handleInputChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={newUser.email}
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Mot de passe"
          value={newUser.password}
          onChange={handleInputChange}
        />
        <button onClick={addUser} className="add-button">Ajouter</button>
      </div>
      <div className="user-list">
        <h2>Utilisateurs existants</h2>
        <ul>
          {users.map(user => (
            <li key={user._id} className="user-item">
              <span>{user.firstName} {user.lastName} - {user.email}</span>
              <button onClick={() => selectUser(user)} className="edit-button">Modifier</button>
              <button onClick={() => deleteUser(user._id)} className="delete-button">Supprimer</button>
              <button onClick={() => showPassword(user.password)} className="password-button">Afficher le mot de passe</button>
            </li>
          ))}
        </ul>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Modifier Utilisateur"
        className="modal"
        overlayClassName="overlay"
      >
        {selectedUser && (
          <div className="edit-user-form">
            <h2>Modifier l'utilisateur</h2>
            <input
              type="text"
              name="firstName"
              placeholder="Prénom"
              value={selectedUser.firstName}
              onChange={handleEditInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Nom"
              value={selectedUser.lastName}
              onChange={handleEditInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={selectedUser.email}
              onChange={handleEditInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Mot de passe"
              value={selectedUser.password}
              onChange={handleEditInputChange}
            />
            <button onClick={editUser} className="save-button">Enregistrer</button>
            <button onClick={() => setIsModalOpen(false)} className="cancel-button">Annuler</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;
