import React, { useState } from 'react';

const EventDialog = ({ isOpen, onClose, onSave, user }) => {
  const [title, setTitle] = useState('');
  const [timeSlot, setTimeSlot] = useState('afternoon');

  const handleSave = () => {
    if (title.trim() !== '') {
      onSave(title, timeSlot, user);
      setTitle('');
      setTimeSlot('afternoon');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
        <h2 className="text-xl mb-4 font-bold">Entrez le nom de la réservation</h2>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded mb-4"
          placeholder="Nom de la réservation"
        />
        <div className="mb-4">
          <label className="block mb-2">Choisissez un créneau :</label>
          <div className="flex space-x-4">
            <button
              className={`px-4 py-2 rounded ${timeSlot === 'morning' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setTimeSlot('morning')}
            >
              08:00 - 12:00
            </button>
            <button
              className={`px-4 py-2 rounded ${timeSlot === 'afternoon' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setTimeSlot('afternoon')}
            >
              13:00 - 19:00
            </button>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Annuler
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDialog;
