import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { toast } from 'react-toastify';
import ConfirmDialog from './ConfirmDialog';
import EventDialog from './EventDialog';
import 'react-toastify/dist/ReactToastify.css';
import './Calendar.css';

const Calendar = ({ user, onLogout }) => {
  const [events, setEvents] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, eventId: null });
  const [eventDialog, setEventDialog] = useState({ isOpen: false, selectInfo: null });

  useEffect(() => {
    fetchEvents();
    const interval = setInterval(() => {
      removePastEvents();
    }, 60000); // Vérifie toutes les minutes
    localStorage.setItem('lastPath', '/calendar'); // Enregistrer le dernier chemin visité

    return () => clearInterval(interval);
  }, []);

  const fetchEvents = () => {
    axios.get('https://lequinze.site/node-app/api/reservations')
      .then(response => {
        setEvents(response.data.map(event => ({
          id: event._id,
          title: event.title,
          start: event.date + 'T' + (event.timeSlot === 'morning' ? '08:00:00' : '13:00:00'),
          end: event.date + 'T' + (event.timeSlot === 'morning' ? '12:00:00' : '19:00:00'),
        })));
      })
      .catch(error => console.error('Erreur lors de la récupération des réservations.'));
  };

  const removePastEvents = () => {
    const now = new Date();
    events.forEach(event => {
      if (new Date(event.end) < now) {
        axios.delete(`https://lequinze.site/node-app/api/${event.id}`, {
          data: { user: `${user.firstName} ${user.lastName}` }
        })
          .then(() => {
            setEvents(events.filter(e => e.id !== event.id));
          })
          .catch(error => console.error('Erreur lors de la suppression des événements passés.'));
      }
    });
  };

  const handleDateSelect = (selectInfo) => {
    const now = new Date();
    const selectedDate = new Date(selectInfo.startStr);

    if (selectedDate < now) {
      toast.error("Vous ne pouvez pas créer un rendez-vous dans le passé.");
      selectInfo.view.calendar.unselect();
      return;
    }

    setEventDialog({ isOpen: true, selectInfo });
  };

  const handleEventSave = (title, timeSlot, user) => {
    const { selectInfo } = eventDialog;
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    const startStr = selectInfo.startStr.split('T')[0];
    const start = timeSlot === 'morning' ? `${startStr}T08:00:00` : `${startStr}T13:00:00`;
    const end = timeSlot === 'morning' ? `${startStr}T12:00:00` : `${startStr}T19:00:00`;

    const newEvent = {
      title: `${title} (${user.firstName} ${user.lastName})`,
      start,
      end,
      allDay: false
    };

    axios.post('https://lequinze.site/node-app/api/reservations', {
      user: `${user.firstName} ${user.lastName}`,
      date: startStr,
      timeSlot,
      title: newEvent.title
    })
      .then(response => {
        const savedEvent = response.data;
        setEvents(events.concat({
          id: savedEvent._id,
          title: savedEvent.title,
          start: savedEvent.date + 'T' + (savedEvent.timeSlot === 'morning' ? '08:00:00' : '13:00:00'),
          end: savedEvent.date + 'T' + (savedEvent.timeSlot === 'morning' ? '12:00:00' : '19:00:00'),
        }));
        toast.success('Réservation effectuée avec succès.');
      })
      .catch(error => {
        console.error('Erreur lors de l\'enregistrement de la réservation.');
        toast.error('Le créneau horaire est déjà réservé.');
      });

    setEventDialog({ isOpen: false, selectInfo: null });
  };

  const handleEventClick = (clickInfo) => {
    setConfirmDialog({
      isOpen: true,
      eventId: clickInfo.event.id
    });
  };

  const handleConfirm = () => {
    axios.delete(`https://lequinze.site/node-app/api/reservations/${confirmDialog.eventId}`, {
      data: { user: `${user.firstName} ${user.lastName}` }
    })
      .then(response => {
        setEvents(events.filter(event => event.id !== confirmDialog.eventId));
        setConfirmDialog({ isOpen: false, eventId: null });
        toast.success('Réservation supprimée avec succès.');
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la réservation.');
        toast.error('Vous n\'êtes pas autorisé à supprimer cette réservation.');
      });
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <img src="/Photo.jpg" alt="Logo" className="" style={{ width: '600px', height: '150px' }} />
        <h2 className="text-xl font-bold">Bienvenue {user.firstName} {user.lastName}</h2>
        <button onClick={onLogout} className="bg-red-500 text-white px-4 py-2 rounded">Déconnexion</button>
      </div>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          message="Voulez-vous supprimer cet événement ?"
          onConfirm={handleConfirm}
          onCancel={() => setConfirmDialog({ isOpen: false, eventId: null })}
        />
      )}
      {eventDialog.isOpen && (
        <EventDialog
          isOpen={eventDialog.isOpen}
          onClose={() => setEventDialog({ isOpen: false, selectInfo: null })}
          onSave={handleEventSave}
          user={user}
        />
      )}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        allDaySlot={false}
        slotMinTime="08:00:00"
        slotMaxTime="19:00:00"
        selectable={true}
        select={handleDateSelect}
        events={events}
        eventClick={handleEventClick}
        locale={frLocale}
        height="auto"
        className="bg-white p-4 rounded shadow-md"
      />
    </div>
  );
};

export default Calendar;
