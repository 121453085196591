// import React from 'react';

// const ConfirmDialog = ({ message, onConfirm, onCancel }) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded shadow-md max-w-sm w-full z-50">
//         <p className="mb-4">{message}</p>
//         <div className="flex justify-end">
//           <button
//             className="bg-red-500 text-white px-4 py-2 rounded mr-2"
//             onClick={onCancel}
//           >
//             Annuler
//           </button>
//           <button
//             className="bg-blue-500 text-white px-4 py-2 rounded"
//             onClick={onConfirm}
//           >
//             Confirmer
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ConfirmDialog;
// src/components/ConfirmDialog.js

import React from 'react';
import './ConfirmDialog.css';

const ConfirmDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded mr-2"
            onClick={onCancel}
          >
            Annuler
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={onConfirm}
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
