import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://lequinze.site/node-app/api/login', { email, password });
      const user = response.data;

      if (user) {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user)); // Enregistrer les données de l'utilisateur
        const path = user.email === 'b.marinet@wanadoo.fr' ? '/dashboard' : '/calendar';
        localStorage.setItem('lastPath', path); // Enregistrer le dernier chemin
        navigate(path);
      } else {
        toast.error('Email ou mot de passe incorrect');
      }
    } catch (error) {
      console.error('Unauthorized access', error);
      toast.error('Email ou mot de passe incorrect');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-sm text-center">
        <img src="/Photo.jpg" alt="Logo" className="" style={{ width: '600px', height: '150px' }} />
        <h2 className="text-2xl mb-6 font-bold">Connexion</h2>
        <form onSubmit={handleSubmit}>
          <label className="block mb-2" htmlFor="email">
            Adresse Email:
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full border p-2 rounded"
            />
          </label>
          <label className="block mb-4" htmlFor="password">
            Mot de Passe:
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1 block w-full border p-2 rounded"
            />
          </label>
          <div className="flex flex-col space-y-2">
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded font-bold">
              Connexion
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
